import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./sidebar";
import "./styles/sidebarStyles.css"

export default function Root(props) {
    const context = props.auth;
    
    return (
        <Router>
            <Sidebar context={context}/>
        </Router>
    )
}
